import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Scrollbars } from 'react-custom-scrollbars';
import MainMenu from '../../Components/MainMenu';
import ViewMemberProfile from '../../Components/ViewMemberProfile';
import { ButtonStandard, ButtonStandardLessMargin } from '../../Components/Button';
import SnackBar from '../../Components/Snackbar';
import MyProfilePropType from '../../PropTypes/MyProfilePropType';
import LoadingScreen from '../../Components/LoadingScreen';
import {
  getAllGroupAsync,
  getMyGroupAsync,
  getOrgAsync,
  removeMemberFromGroup,
  removeMemberFromOrganization,
} from '../../Actions/groupsActions';
import { getNotificationsMessages } from '../../Actions/notificationsActions';
import changeTab from '../../Actions/mainTabsActions';
import {
  getMemberProfile,
  requestJoin,
  updateOrgNotificationPreferences,
  removeMemberFromWideCommunication
} from '../../Actions/memberActions';
import { logout } from '../../Actions/loginActions';
import './index.sass';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import snackBarStatus from '../../Actions/snackbarActions';
import { forceReload } from '../../Actions/AvatarActions';
import { getUserProfile } from '../../Actions/userActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MyGroupsList from '../../Components/MyGroupsList';
import MyAvailableGroupsList from '../../Components/MyAvailableGroupsList';
import PasswordChangePad from '../PasswordChangePad';
import { withRouter } from 'react-router-dom';

/**
 * @Component MyProfile
 */
class MyProfile extends Component {
  state = {
    displayChangePassword: true,
    organizationIdRemove: null,
    dialogState: false,
    isRemoveConfirmationOpen: false,
    allMembersGroupId: null,
    userId: null,
  };

  componentDidMount() {
    const { history } = this.props;
    
    this.props.changeTab({ payload: { value: -1 } });
    this.props.getUserProfile();

    if (localStorage.getItem('token')) {
      this.props.getMemberProfile();
      this.props.getOrgAsync().then(response => {
        if (response && response[0]) {
          this.props.getMyGroupAsync(response[0].organization.public_id);
        }
      });

      this.props.getNotificationsMessages();
    } else {
      history.push('/login')
    }
  }

  displayChangePasswordView = displayChangePassword => {
    this.setState({ displayChangePassword });
  };

  /**
   * When user clicks anywhere when bubble is open this should close it.
   * Requested by curtis.
   */
  closeBubble = () => {
    if (this.state.open) {
      this.setState({
        open: false,
      });
    }
  };

  // TODO: duplicated
  handleSelectTypeNotification = (group, opts, isAllMemberGroup) => {
    const { selectedMember: { publicId, email, phoneNumber } } = this.props;

    if (opts.isNotifyByEmail && !email) {
      this.props.snackBarStatus({
        payload: {
          title: 'To receive notifications through email, you need to specify one first.',
          enable: true,
          type: 'error',
        },
      });
    } else if (opts.isNotifyBySms && !phoneNumber) {
      this.props.snackBarStatus({
        payload: {
          title: 'To receive SMS notifications, you need to provide a phone number first.',
          enable: true,
          type: 'error',
        },
      });
    } else {
      this.props.updateOrgNotificationPreferences(
        opts,
        isAllMemberGroup ? group.all_member_group_id : group.group.public_id,
        publicId,
      )
    }
  };

  handleRemoveFromGroup = groupId => {
    const { selectedMember: { publicId } } = this.props;
    this.props.removeMemberFromGroup({ groupId, memberId: publicId }).then(() => {
      this.props.getOrgAsync().then(response => {
        this.props.getMyGroupAsync(response[0].organization.public_id);
      });
    });
  };

  handleModalOpen = (orgId) => {
    this.setState({
      dialogState: true,
      organizationIdRemove: orgId,
    });
  };

  handleModalClose = () => {
    this.setState({
      dialogState: false,
    });
  };

  // TODO: changes
  removeFromOrgConfirm = () => {
    const { organizationIdRemove } = this.state;
    const { selectedMember: { publicId } } = this.props;
    this.props.removeMemberFromOrganization({ organizationIdRemove, publicId })
      .then(() => {
        // this.closeRemoveMemberOrganization();
        this.props.getOrgAsync().then(response => {
          this.props.getMyGroupAsync(response[0].organization.public_id);
        });
      });

    this.handleModalClose();
  };

  handleAddGroup = groupId => {
    this.props.requestJoin(groupId);
    this.props.getOrgAsync().then(response => {
      this.props.getMyGroupAsync(response[0].organization.public_id);
    });
  };

  submitLogout = e => {
    e.preventDefault();
    this.props.navigationControllerRedirect('/logout');
  };

  renderPageBody = () => {
    // TODO: move most of states to MyGroupsList.
    const {
      showChangePassword,
      displayEditMemberForm,
      isAdminView,
      availableGroups,
      myGroups,
    } = this.props;

    const {
      displayChangePassword,
      dialogState,
    } = this.state;
    const WindowHeight = window.innerHeight - 140;

    return (
      <React.Fragment>
        <Scrollbars
          autoHeight
          autoHide
          autoHeightMin={WindowHeight}
          autoHeightMax={WindowHeight}
          className="custom-scrollbar"
          style={{ maxWidth: '1230px', margin: '0 auto' }}
        >
          <div
            id="my-profile"
            className="my-profile"
            style={{ minHeight: WindowHeight }}
            onClick={this.closeBubble}
            onKeyPress={() => undefined}
            role="button"
            tabIndex="0"
          >
            <div className="container-left">
              <ViewMemberProfile />
              <Grid item xs={12} id="change-password-button" className="change-password-button">
                {displayChangePassword && showChangePassword ? (
                  <ButtonStandard
                    title="Change Password"
                    onClick={() => this.displayChangePasswordView(false)}
                  />
                ) : (
                  showChangePassword && (
                    <PasswordChangePad displayChangePasswordView={this.displayChangePasswordView} />
                  )
                )}
              </Grid>
              {!displayEditMemberForm && !isAdminView && (
                <div id="logout" className="logout">
                  <ButtonStandardLessMargin title="logout" onClick={this.submitLogout} />
                </div>
              )}
            </div>
            <div className="container-right">
              <MyGroupsList
                groups={myGroups}
                onRemoveFromGroupClick={this.handleRemoveFromGroup}
                onConfirmationModalCall={this.handleModalOpen}
                onSelectTypeNotification={this.handleSelectTypeNotification}
                userId={this.props.userProfile.member.public_id}
                onOpenDialog={this.handleOpenDialog}
              />

              <MyAvailableGroupsList groups={availableGroups} onAddGroup={this.handleAddGroup} />

            </div>
          </div>
          <SnackBar />
        </Scrollbars>

        <Dialog
          open={dialogState}
          onClose={this.handleModalClose}
          className="return-to-edit"
          maxWidth="sm"
          style={{
            backgroundColor: 'transparent',
            borderRadius: '10px',
          }}
        >
          <DialogContent className="container-warning-button">
            <div className="warning-Settings">
              <div className="single-line">
                <strong> WARNING:</strong>
                This action will remove you from all communications
                of this organization and its groups. This can not be undone
                <div />
                <strong>Are you sure you want to proceed?</strong>
              </div>
            </div>

            <button onClick={this.removeFromOrgConfirm} className="button-warning">
                    <span className="text-warning">
                      Yes, remove me from this organization
                    </span>
            </button>
            <button onClick={this.handleModalClose} className="button-warning">
              <span className="text-warning">No, I still want to receive communications</span>
            </button>
          </DialogContent>
        </Dialog>
        {this.renderDialog()}
      </React.Fragment>
    )
  }

  handleOpenDialog = (allMembersGroupId, userId) =>
    this.setState({
      isRemoveConfirmationOpen: true,
      allMembersGroupId,
      userId,
    })

  handleCloseDialog = () =>
    this.setState({ isRemoveConfirmationOpen: false })

  handleRemoveMemberFromWideCommunication = () => {
    this.props.removeMemberFromWideCommunication(this.state.allMembersGroupId, this.state.userId)
    this.handleCloseDialog()
  }

  confirmationMessage = () =>
    this.props.userProfile.isAdmin ?
      `Warning! This user has opted out of communication. Please be sure you have authorization to send this user messages. They will receive welcome message. Click YES to continue or NO to go back.` :
      `Warning! You have opted out of communication. Click YES to continue or NO to go back.`;

  renderDialog = () => (
    // TODO: unify
    <div>
      <Dialog
        open={this.state.isRemoveConfirmationOpen}
        onClose={this.handleCloseDialog}
        className="return-to-edit"
        style={{
          backgroundColor: 'transparent',
          borderRadius: '15px',
          zIndex: '1300000',
        }}
      >
        <DialogContent className="return-to-edit-modal-message">
          <div className="attention-Settings">
            {this.confirmationMessage()}
          </div>
          <div className="modal-actions">
            <button onClick={this.handleRemoveMemberFromWideCommunication} className="button">
              Yes
            </button>
            <button onClick={this.handleCloseDialog} className="button">
              No
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );

  /* eslint-disable  max-len */
  render() {
    const {
      isAdminView,
      myGroups,
      availableGroups,
      selectedMember,
    } = this.props;
    // console.log('state:', this.state.allMembersGroupId, this.state.userId)
    if (!selectedMember.publicId) {
      return <LoadingScreen loadingText="Loading Profile Information..." />
    }

    if (!myGroups && !availableGroups) {
      return null;
    }

    if (!isAdminView) {
      return this.renderPageBody();
    } else {
      return (
        <MainMenu
          value={0}
          className="general-eva"
          content={this.renderPageBody()}
        />
      );
    }

  }
}

MyProfile.propTypes = MyProfilePropType;

const mS = state => ({
  myGroups: state.groupsReducer.globalGroups.myGroups,
  availableGroups: state.groupsReducer.globalGroups.availableGroups,
  selectedMember: state.memberReducer.selectedMember,
  showChangePassword: state.memberReducer.showChangePassword,
  displayEditMemberForm: state.memberReducer.displayEditMemberForm,
  userProfile: state.userProfileReducer,
  loggedUser: state.loginReducer,
});

const mD = {
  getAllGroupAsync,
  getMyGroupAsync,
  getOrgAsync,
  getMemberProfile,
  requestJoin,
  updateOrgNotificationPreferences,
  removeMemberFromWideCommunication,
  removeMemberFromGroup,
  logout,
  navigationControllerRedirect,
  snackBarStatus,
  removeMemberFromOrganization,
  forceReload,
  getUserProfile,
  getNotificationsMessages,
  changeTab,
};

export default withRouter(connect(mS, mD,)(MyProfile));

